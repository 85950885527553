import axios from 'axios';

export async function lookupMobilizeEvents(zipcode: string) {
  const { data } = await axios.get<{ data: Event[] }>(
    `https://api.mobilize.us/v1/organizations/${process.env.REACT_APP_MOBILIZE_ORG_ID}/events`,
    {
      params: {
        zipcode,
        max_dist: 50,
      },
    }
  );

  return data.data;
}

type City = string;
type TwoCharStateCode = string;

type Timezone =
  | 'America/New_York'
  | 'Pacific/Honolulu'
  | 'America/Los_Angeles'
  | 'America/Denver'
  | 'America/Phoenix'
  | 'America/Chicago';

type EventType =
  | 'CANVASS'
  | 'PHONE_BANK'
  | 'TEXT_BANK'
  | 'MEETING'
  | 'COMMUNITY'
  | 'FUNDRAISER'
  | 'MEET_GREET'
  | 'HOUSE_PARTY'
  | 'VOTER_REG'
  | 'TRAINING'
  | 'FRIEND_TO_FRIEND_OUTREACH'
  | 'DEBATE_WATCH_PARTY'
  | 'ADVOCACY_CALL'
  | 'RALLY'
  | 'TOWN_HALL'
  | 'OFFICE_OPENING'
  | 'BARNSTORM'
  | 'SOLIDARITY_EVENT'
  | 'COMMUNITY_CANVASS'
  | 'SIGNATURE_GATHERING'
  | 'CARPOOL'
  | 'OTHER';

export interface Location {
  venue: string;
  address_lines: string[];
  locality: City;
  region: TwoCharStateCode;
  country: 'US';
  postal_code: string;
  location: {
    latitude: number;
    longitude: number;
  };
  congressional_district: string;
  state_leg_district: string;
  state_senate_district: string;
}

export interface Event {
  id: number;
  title: string;
  summary: string;
  description: string;
  timeslots: Timeslot[];
  location: Location;
  is_virtual: boolean;
  approval_status:
    | 'APPROVED'
    | 'NEEDS_APPROVAL'
    | 'REJECTED'
    | 'NEEDS_HOST_VERIFICATION';
  timezone: string;
  event_type: EventType;
  visibility: 'PUBLIC' | 'PRIVATE';
  contact: Contact;
  accessibility_status: 'NOT_ACCESSIBLE' | 'NOT_SURE' | 'ACCESSIBLE';
  tag_ids: number[];
  instructions: string;
}

export type CreateTimeslot = Omit<Timeslot, 'id' | 'is_full'>;

export interface CreateEvent {
  title: string;
  summary?: string;
  description: string;
  timeslots: CreateTimeslot[];
  location?: { postal_code: string } & Partial<Location>;
  is_virtual?: boolean;
  timezone: string;
  event_type: EventType;
  visibility: 'PUBLIC' | 'PRIVATE';
  contact: Omit<Contact, 'owner_user_id'>;
  accessibility_status?: 'NOT_ACCESSIBLE' | 'NOT_SURE' | 'ACCESSIBLE';
  accessibility_notes?: string;
  featured_image_url?: string;
  tag_ids?: number[];
  instructions?: string;
}

type Timestamp = number;

export interface Timeslot {
  id: number;
  start_date: Timestamp;
  end_date: Timestamp;
  max_attendees?: number;
  is_full: boolean;
  instructions: string;
}

export interface Tag {
  id: number;
  name: string;
}
export interface Contact {
  name: string;
  email_address: string;
  phone_number: string;
  owner_user_id: number;
}
export interface EventCampaign {
  id: number;
  slug: string;
  event_create_page_url: string;
}
