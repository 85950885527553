import React, { useEffect } from 'react';
import Axios from 'axios';
import processGooglePlaceData, { OurPlace } from '../../processGooglePlaceData';
import Autocomplete from 'react-google-autocomplete';
import { FormError } from '../../FormComponents';

export interface DropoffLocation {
  address: {
    locationName: string;
    line1: string;
    line2: string;
    line3: string;
    city: string;
    state: string;
    zip: string;
  };
  pollingHours: string;
  voterServices: string;
  latitude: number;
  longitude: number;
  startDate: string;
  endDate: string;
  notes: string;
  addressString: string;
  distance: number;
}

interface Source {
  name: string;
  official: boolean;
}

export interface LocalJurisdiction {
  name: string;
  electionAdministrationBody: {
    name: string;
    physicalAddress: {
      line1: string;
      city: string;
      state: string;
      zip: string;
    };
    electionOfficials: Array<{
      name: string;
      officePhoneNumber: string;
    }>;
    sources: Array<Source>;
  };
}

export interface GoogleDestinationChoice {
  dropoffLocation: DropoffLocation;
  place: OurPlace;
}

export function PickDestinationFromGoogle({
  interest,
  onChoose,
  onTrouble,
}: {
  interest: Record<string, any>;
  onChoose: (choice: GoogleDestinationChoice) => any;
  onTrouble: () => any;
}) {
  const [homeAddress, setHomeAddress] = React.useState<OurPlace>(null);
  const [homeAddressDirty, setHomeAddressDirty] = React.useState(false);
  const [serverError, setServerError] = React.useState(null);
  const [loadingDropOffLocations, setLoadingDropOffLocations] = React.useState(
    false
  );

  const [dropOffLocations, setDropOffLocations] = React.useState<
    DropoffLocation[] | null
  >(null);

  const getBallotDropOffLocations = React.useCallback(
    async (homeAddress: OurPlace) => {
      setLoadingDropOffLocations(true);
      try {
        const { data } = await Axios.get<{
          dropoffLocations: DropoffLocation[];
          localJurisdiction: LocalJurisdiction;
        }>(`${process.env.REACT_APP_API_URL}/civic-data`, {
          params: {
            address: homeAddress?.formattedAddress,
            lat: homeAddress.lat,
            lng: homeAddress.lng,
          },
        });
        setLoadingDropOffLocations(false);
        setDropOffLocations(data.dropoffLocations || []);
      } catch (e) {
        setServerError(e);
      }
    },
    [setLoadingDropOffLocations, setDropOffLocations, setServerError]
  );

  React.useEffect(() => {
    // if (dropOffLocations) {
    //   return;
    // }
    if (interest?.civicDataAvailable && homeAddress) {
      getBallotDropOffLocations(homeAddress);
    }
  }, [homeAddress, interest, getBallotDropOffLocations]);

  const lookupAddress = React.useCallback(
    async (address: string, lat: number, lng: number) => {
      const { data } = await Axios.get<OurPlace[]>(
        `${process.env.REACT_APP_API_URL}/location-lookup`,
        {
          params: {
            input: address,
            lat,
            lng,
          },
        }
      );
      const [firstPlace] = data;
      if (!firstPlace) {
        throw new Error('no addresses found!');
      }
      return firstPlace;
    },
    []
  );

  const chooseDropoffLocation = React.useCallback(
    async (location: DropoffLocation) => {
      const place = await lookupAddress(
        location.addressString,
        interest.lat,
        interest.lng
      );
      onChoose({
        dropoffLocation: location,
        place,
      });
    },
    [lookupAddress, interest, onChoose]
  );

  return (
    <>
      <p>
        To look up ballot drop off locations near you, we'll need your home
        address. We will not store this data.
      </p>
      <div className="p-4 my-2 border rounded contains-autocomplete">
        <Autocomplete
          style={{ width: '90%' }}
          onBlur={async () => setHomeAddressDirty(true)}
          placeholder="Your home address"
          onPlaceSelected={(googleApiData) => {
            setHomeAddress(processGooglePlaceData(googleApiData));
          }}
          types={['address']}
          onChange={() => setDropOffLocations(null)}
          componentRestrictions={{ country: 'us' }}
        />
      </div>
      {homeAddressDirty && !homeAddress && (
        <FormError>
          Please pick an address from the autocomplete list{' '}
        </FormError>
      )}
      {loadingDropOffLocations && <div>Looking for dropoff locations ...</div>}
      {!!dropOffLocations?.length && (
        <ul>
          {dropOffLocations.slice(0, 9).map((location) => (
            <li
              key={location.address.line1 + location.notes}
              className="flex items-center py-2 border-b leading-snug"
            >
              <input
                className="mr-4"
                type="radio"
                name="destination"
                value={location.address.line1 + location.notes}
                id={location.address.line1 + location.notes}
                onChange={() => {
                  chooseDropoffLocation(location);
                }}
              />
              <label
                htmlFor={location.address.line1 + location.notes}
                className="flex flex-col w-full"
              >
                <div className=" flex flex-row justify-between w-full">
                  <div className="font-bold">
                    {location.address.locationName}
                  </div>
                </div>
                <div>
                  {location.address.line1}
                  {', '}
                  {`${location.address.city}, ${location.address.state}`}
                </div>
                <div className="text-sm">
                  <span className="font-bold">Hours:</span>{' '}
                  {location.pollingHours}
                </div>
              </label>
            </li>
          ))}
        </ul>
      )}
      {(!!dropOffLocations || serverError) && (
        <div className="flex flex-row justify-center items-center p-8">
          <button
            type="button"
            onClick={onTrouble}
            className="rounded bg-gray-200 hover:bg-gray-300 p-2"
          >
            🤔 Not seeing what you're looking for? You can set your dropoff
            manually...
          </button>
        </div>
      )}
    </>
  );
}
