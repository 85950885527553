import * as React from 'react';
import Header from '../../Header';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

export default function ThankYou() {
  const { width, height } = useWindowSize();
  return (
    <div className="mx-3 md:mx-auto md:max-w-2xl pt-2 md:pt-12">
      <Confetti width={width} height={height} />
      <Header />
      <div className="mt-6 p-6 bg-white border shadow-lg rounded-lg">
        <h2 className="font-bold">Thank you</h2>
        <p className="py-3  leading-7">
          Stay tuned for an email from our partner Mobilize with all the
          instructions you need. It will include:
        </p>
        <ul className="list-disc ml-8">
          <li>
            A personalized webpage for your own #walkthevote, which you can
            share with friends and neighbors
          </li>
          <li>A suggested walking route</li>
          <li>An invitation to a training call where you can ask questions</li>
        </ul>
      </div>
    </div>
  );
}
