export interface OurPlace {
  city: string;
  state: string;
  shortState: string;
  county: string;
  zip: string;
  formattedAddress: string;
  placeId: string;
  lat: string;
  name?: string;
  lng: string;
}

export default function processGooglePlaceData(place): OurPlace {
  const city = place.address_components.find((component) =>
    component.types.includes('locality')
  )?.long_name;

  const state = place.address_components.find((component) =>
    component.types.includes('administrative_area_level_1')
  )?.long_name;

  const shortState = place.address_components.find((component) =>
    component.types.includes('administrative_area_level_1')
  )?.short_name;

  const county = place.address_components.find((component) =>
    component.types.includes('administrative_area_level_2')
  )?.long_name;

  const zip = place.address_components.find((component) =>
    component.types.includes('postal_code')
  )?.long_name;
  return {
    city,
    state: shortState,
    zip,
    formattedAddress: place.formatted_address,
    placeId: place.place_id,
    county,
    shortState,
    lat: place.geometry?.location?.lat().toString(),
    lng: place.geometry?.location?.lng().toString(),
  };
}
