import React from 'react';
import classNames from 'classnames';
import { ValidationRules } from 'react-hook-form';

export const UpperCaseLabel = ({ children }) => (
  <label className="my-4 block uppercase text-sm text-gray-700">
    {children}
  </label>
);

export const Label = ({ children }) => (
  <label className="my-4 block">{children}</label>
);

export const Button = ({
  children,
  className,
  disabled = false,
  type = 'button',
  ...buttonProps
}: { disabled?: boolean; type?: 'submit' | 'button' } & React.HTMLAttributes<
  HTMLButtonElement
>) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames([
        {
          [`transition-colors duration-200 bg-brand-purple hover:bg-purple-600 text-white py-2 w-full rounded ${className}`]: !disabled,
          [` bg-gray-200 text-black py-2 w-full rounded cursor-not-allowed`]: disabled,
        },
      ])}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export const FormError = ({ children }) => (
  <div className=" mb-2 text-sm text-red-700">{children}</div>
);

export const Input = ({
  register,
  registerArgs,
  additionalClasses,
  placeholder,
  ...otherProps
}: {
  register: (a: any) => any;
  registerArgs: ValidationRules;
  type: string;
  name: string;
  additionalClasses?: string;
} & React.HTMLAttributes<HTMLInputElement>) => (
  <input
    ref={register(registerArgs)}
    className={`px-4 py-3 w-full rounded border ${additionalClasses}`}
    placeholder={placeholder}
    {...otherProps}
  />
);

export const Select = ({
  register,
  registerArgs,
  children,
  additionalClasses,
  ...otherProps
}: {
  register: (a: any) => any;
  registerArgs: any;
  children: any;
  name: string;
  additionalClasses?: string;
} & React.HTMLAttributes<HTMLSelectElement>) => (
  <div className="inline-block relative w-24">
    <select
      ref={register(registerArgs)}
      className={`block w-full leading-tight px-4 py-3 appearance-none rounded border ${additionalClasses}`}
      {...otherProps}
    >
      {children}
    </select>
    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg
        className="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </div>
  </div>
);
