import React, { useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { useParams, useHistory } from 'react-router';
import { Button, FormError } from '../../FormComponents';
import Axios from 'axios';
import classNames from 'classnames';
import Header from '../../Header';
import processGooglePlaceData, { OurPlace } from '../../processGooglePlaceData';
import { useForm } from 'react-hook-form';
import { formatAddress, getFirstName } from '../../strUtilities';
import * as Directions from '../../WalkingDirections';
import {
  PickDestinationFromGoogle,
  DropoffLocation,
  GoogleDestinationChoice,
} from './PickDestinationFromGoogle';

export const usDateFormatter = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const dates = [
  new Date('Sunday, September 27 2020'),
  new Date('Sunday, October 4 2020'),
  new Date('Saturday, October 10 2020'),
  new Date('Sunday, October 11 2020'),
  new Date('Saturday, October 17 2020'),
  new Date('Sunday, October 18 2020'),
  new Date('Saturday, October 24 2020'),
  new Date('Sunday, October 25 2020'),
  new Date('Saturday, October 31 2020'),
  new Date('Sunday, November 1 2020'),
  new Date('Monday, November 2 2020'),
  new Date('Tuesday, November 3 2020'),
].filter((d) => d > new Date());

const DateCheckbox = ({
  date,
  register,
  registerArgs,
  warning,
}: {
  date: Date;
  register: (args) => any;
  registerArgs: any;
  warning: boolean;
}) => {
  return (
    <label
      className={classNames(['flex items-center cursor-pointer'], {
        'text-gray-500': warning,
      })}
    >
      <input
        type="checkbox"
        ref={register(registerArgs)}
        name="dates"
        className="mr-2"
        value={date.toISOString()}
      />
      {usDateFormatter.format(date)}
    </label>
  );
};

interface FormData {
  dates: string[];
}

interface CompoundBurden {
  burden: Directions.Burden;
  burdenEnglish: Directions.BurdenEnglish;
}

export default function MarchDetailsForm() {
  const { register, errors, handleSubmit } = useForm<FormData>();
  const [loading, setLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const { push } = useHistory();

  const { interestId } = useParams<{ interestId: string }>();
  const [interest, setInterest] = React.useState(null);

  const initilizeMarchDetails = React.useCallback(async () => {
    setLoading(true);
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/interest/${interestId}`
    );

    setInterest(data);
    window['Intercom']('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: data.id,
      email: data.email,
      name: data.fullName,
      created_at: data.createdAt,
    });
    setLoading(false);
  }, [setLoading, interestId, setInterest]);

  useEffect(() => {
    initilizeMarchDetails();
  }, [initilizeMarchDetails]);

  const [origin, setOrigin] = React.useState<OurPlace>(null);
  const [originDirty, setOriginDirty] = React.useState(false);
  const [destination, setDestination] = React.useState<OurPlace>(null);
  const [destinationDirty, setDestinationDirty] = React.useState(false);
  const [googleDropoffLocation, setGoogleDropoffLocation] = React.useState<
    DropoffLocation
  >();

  const minDate = React.useMemo(() => {
    if (googleDropoffLocation?.startDate) {
      return new Date(googleDropoffLocation.startDate);
    }
  }, [googleDropoffLocation]);

  const [
    havingTroblemsWithGooglePicker,
    setHavingTroblemsWithGooglePicker,
  ] = React.useState(false);

  const [routeBurden, setRouteBurden] = React.useState<CompoundBurden | null>(
    null
  );

  const fetchRouteBurden = React.useCallback(
    async (origin: string, destination: string) => {
      try {
        setSubmitLoading(true);
        const { data } = await Axios.get<CompoundBurden>(
          `${process.env.REACT_APP_API_URL}/directions/summary`,
          {
            params: {
              origin,
              destination,
            },
          }
        );
        setRouteBurden(data);
        return data;
      } catch (e) {
      } finally {
        setSubmitLoading(false);
      }
    },
    [setRouteBurden, setSubmitLoading]
  );

  useEffect(() => {
    if (origin && destination && !routeBurden) {
      if (destination.placeId) {
        fetchRouteBurden(origin.placeId, destination.placeId);
      }
    }
  }, [origin, destination, routeBurden, fetchRouteBurden]);

  const submit = React.useCallback(
    async (fd: FormData) => {
      const isComplete = origin && destination;
      if (!isComplete) {
        return;
      }
      setSubmitLoading(true);
      await Axios.post(`${process.env.REACT_APP_API_URL}/march`, {
        origin,
        destination,
        interestId,
        dates: fd.dates,
        distance: routeBurden?.burden.distance,
        duration: routeBurden?.burden.duration,
      });
      setSubmitLoading(false);
      push('/details/thank-you');
    },
    [push, setSubmitLoading, origin, destination, interestId, routeBurden]
  );

  const chooseGoogleDestination = React.useCallback(
    (choice: GoogleDestinationChoice) => {
      setDestinationDirty(true);
      setDestination(choice.place);
      setGoogleDropoffLocation(choice.dropoffLocation);
    },
    [setDestination, setDestinationDirty, setGoogleDropoffLocation]
  );

  if (interest?.mobilizeId) {
    return (
      <div className="z-10 mx-2 md:mx-auto md:max-w-3xl p-24">
        <Header showSubtext={false} />
        <div className="px-6 bg-white rounded-lg p-5 md:m-16 sm:border sm:shadow-lg text-gray-700">
          <p>
            It seems that someone (you?) has already submitted the information
            for your walk. Please check your inbox for instructions on planning
            the rest of your #walkthevote.
          </p>

          <p>
            We have the information for your walk{' '}
            <a
              target="_blank"
              href={`https://www.mobilize.us/walkthevote/event/${interest?.mobilizeId}/`}
            >
              here
            </a>
            , so you can share it with your friends and neighbors.
          </p>

          <p>
            Email us if you have any questions, to questions@wewalkthevote.com.
          </p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="z-10 mx-2 md:mx-auto md:max-w-3xl p-24">
        <Header showSubtext={false} />
        <div className="text-lg md:text-2xl font-bold text-brand-purple mb-3 text-center">
          Loading!
        </div>
      </div>
    );
  }

  const firstName = getFirstName(interest?.fullName);
  const locationStr = formatAddress(interest?.originAddress[0]);

  const shouldShowGooglePicker =
    interest?.civicDataAvailable[0] && !havingTroblemsWithGooglePicker;

  return (
    <>
      <div className="z-10 mx-2 md:mx-auto md:max-w-3xl ">
        <Header showSubtext={false} />
        <div className="px-6 bg-white rounded-lg p-5 md:m-16 sm:border sm:shadow-lg text-gray-700">
          <h1 className="text-lg md:text-2xl font-bold text-brand-purple mb-3 ">
            Next steps for {firstName}'s #walkthevote in {locationStr}
          </h1>
          <p>
            We need you to answer a few questions to plan your #walkthevote, and
            then we’ll send you all the information you need to make it a
            success.
          </p>

          <p>
            If you have any questions while you’re answering these three
            questions, ask us at questions@wewalkthevote.com. You can only
            submit this form once, though you’re welcome to come back to it when
            you have time.
          </p>

          <p>
            If you need to make changes to the details of your walk (start
            location, end location, or time/date), after you submit this form,
            you can always email us at questions@wewalkthevote.com. We’ll
            incorporate your changes.
          </p>

          <form noValidate onSubmit={handleSubmit(submit)}>
            <div className="my-4">
              <h2 className="font-bold mt-6">
                1. Where will you drop off your ballots?
              </h2>
              {shouldShowGooglePicker ? (
                <PickDestinationFromGoogle
                  interest={interest}
                  onChoose={chooseGoogleDestination}
                  onTrouble={() => {
                    setHavingTroblemsWithGooglePicker(true);
                  }}
                />
              ) : (
                <>
                  <p>
                    Look up your{' '}
                    <a
                      href="https://www.voteamerica.com/local-election-offices/"
                      target="_blank"
                    >
                      local election office
                    </a>{' '}
                    to find the nearest location where you can drop your
                    ballots.
                  </p>
                  <p>
                    Some states have websites where you can look this up, some
                    don’t. We would give you locations near you, it’s just that
                    the data change fast enough that we only want to give you
                    data if it’s accurate. So you’ll need to look up the
                    information yourself.
                  </p>
                  <p>
                    Call your local election office to confirm where you can
                    drop off ballots, and let them know you plan to bring a
                    group. (If you can’t call them now, it’s OK to enter in the
                    address where you think you can drop off ballots -- and we
                    can work with you later to change it.)
                  </p>
                  <p>Then enter your destination here:</p>
                  <div className="shadow px-4 py-3 my-2 border rounded contains-autocomplete">
                    <Autocomplete
                      style={{ width: '90%' }}
                      onBlur={() => setDestinationDirty(true)}
                      placeholder="Ballot dropoff location for your #walkthevote"
                      onPlaceSelected={(googleApiData) =>
                        setDestination(processGooglePlaceData(googleApiData))
                      }
                      types={['address']}
                      componentRestrictions={{ country: 'us' }}
                    />
                  </div>
                  {destinationDirty && !destination && (
                    <FormError>
                      Please pick an address from the autocomplete list{' '}
                    </FormError>
                  )}

                  <p>
                    So you know: You can deliver ballots to different types of
                    places, depending on where you live.
                  </p>

                  <ul className="ml-6 mb-2 list-disc">
                    <li>
                      Unstaffed ballot drop boxes are OK. They’re always “open,”
                      but everyone on your walk will need to bring their
                      absentee ballots -- and if you have questions, there won’t
                      be any poll workers to answer them.
                    </li>
                    <li>
                      Polling places are better. There are people there who can
                      help. They might not be open when you want to walk.
                    </li>
                    <li>
                      Local election offices (sometimes called clerk’s offices
                      or some other name) are the best. They’re staffed with the
                      most knowledgeable people.
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="my-4">
              <h2 className="font-bold mt-6">
                2. When do you want to lead your #walkthevote
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                {dates.map((d) => (
                  <DateCheckbox
                    register={register}
                    date={d}
                    warning={minDate && minDate > d}
                    key={d.toString()}
                    registerArgs={{
                      required: true,
                      validate: (value) => value.length >= 1,
                    }}
                  />
                ))}
              </div>
              {errors.dates && (
                <FormError>Please select at least one date</FormError>
              )}
              <p>
                We ask that all #walkthevote events happen at 12pm, though if
                you need to change this (or pick a date that isn’t available
                here) let us know after you submit this form and we can make
                changes.
              </p>
            </div>

            <div className="my-4">
              <h2 className="font-bold mt-6">
                3. Last question! Where will you start your walk?
              </h2>
              <p>
                You know your neighborhood better than we do. Pick an outdoor
                location that’s easy to access. Make it about half a mile or
                less away from your dropoff location, so people of all abilities
                can make it comfortably. Think about:
              </p>
              <ul className="ml-6 mb-2 list-disc">
                <li>
                  Parking or public transportation or whatever works in your
                  neighborhood and community.
                </li>
                <li>
                  Make sure the space allows for social distancing -- standing 6
                  feet apart while you gather.
                </li>
                <li>
                  You may be waiting there for walkers to arrive, so if at all
                  possible try to choose a place where people can stay warm and
                  use a restroom.
                </li>
                <li>
                  Some people pick a parking lot near a cafe, others pick a
                  farmer’s market -- use your judgment for what you think is
                  best.
                </li>
              </ul>
              <div className="shadow px-4 py-3 my-2 border rounded contains-autocomplete">
                <Autocomplete
                  style={{ width: '90%' }}
                  onBlur={() => setOriginDirty(true)}
                  placeholder="Meeting place for your #walkthevote"
                  onPlaceSelected={(googleApiData) =>
                    setOrigin(processGooglePlaceData(googleApiData))
                  }
                  types={['address']}
                  componentRestrictions={{ country: 'us' }}
                />
              </div>
              {originDirty && !origin && (
                <FormError>
                  Please pick an address from the autocomplete list{' '}
                </FormError>
              )}
            </div>

            {routeBurden && (
              <div>
                {routeBurden.burden.distance > 1608 ? (
                  <p className="text-red-600">
                    Your walking route might be a little long. Google estimates
                    it at {routeBurden.burdenEnglish.distance},{' '}
                    {routeBurden.burdenEnglish.duration}. You know your
                    community better than us, but consider picking a starting
                    point closer to where you'll be dropping off ballots.
                  </p>
                ) : (
                  <p>
                    Your walk will be {routeBurden.burdenEnglish.distance}{' '}
                    taking about{' '}
                    <span>{routeBurden.burdenEnglish.duration}</span>
                  </p>
                )}
              </div>
            )}

            <p>
              We will share your email address and name with people who walk in
              your #walkthevote, in case they need to ask you questions.
            </p>
            <div>
              <Button
                disabled={submitLoading}
                className={classNames([
                  'mt-6 p-4',
                  { 'cursor-wait bg-gray-200 text-black': submitLoading },
                ])}
                type="submit"
              >
                Submit
              </Button>
              {submitError && <FormError>{submitError}</FormError>}
            </div>
            <p>
              After you press the submit button, the next step is on us: Based
              on this information, we’ll send you all the instructions you’ll
              need to recruit as many of your friends and neighbors to
              #walkthevote with you (including a webpage just for your walk that
              you can send to folks).
            </p>
          </form>
        </div>
      </div>
    </>
  );
}
