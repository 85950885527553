import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Label, Input, Select, Button, FormError } from '../../FormComponents';
import Axios from 'axios';
import classNames from 'classnames';
import Header from '../../Header';

import phoneValidator from 'google-libphonenumber';
import NewYorkTimes from '../../NYTimes';
import useQuery from '../../hooks/useQuery';
import Fox from '../../Fox';
import ABC from '../../ABC';
import getStats, { Stats } from './getStats';

interface FormValues {
  email: string;
  phone: string;
  zip: string;
  type: 'walker' | 'captain';
  fullName: string;
}

const phoneUtil = new phoneValidator.PhoneNumberUtil();

export default function RegisterInterestForm() {
  const query = useQuery();
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();

  const zipFromQuery = React.useMemo(() => query.get('zip') || '', [query]);

  const { register, handleSubmit, trigger, errors, watch, setValue } = useForm<
    FormValues
  >();

  React.useEffect(() => {
    window['Intercom']('boot', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    });
  }, []);

  const [stats, setStats] = React.useState<Stats>(null);
  useEffect(() => {
    if (!stats) {
      getStats().then((stats) => setStats(stats));
    }
  }, [setStats, stats]);

  const currentValues = watch();

  const submit = React.useCallback(
    async (fd: FormValues) => {
      setLoading(true);
      await Axios.post(`${process.env.REACT_APP_API_URL}/interest`, {
        type: fd.type,
        email: fd.email,
        fullName: fd.fullName,
        phone: fd.phone,
        zip: fd.zip,
        referrer: query.get('referrer'),
      });
      setLoading(false);
      push(`/interest/${fd.type}/thank-you?zip=${fd.zip}`);
    },
    [setLoading, push, query]
  );

  useEffect(() => {
    trigger('type');
  }, []);

  return (
    <>
      <div className="z-10 mx-2 md:mx-auto md:max-w-3xl ">
        <Header />

        {stats && (
          <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 sm:mx-32 mt-4">
            <div className="flex flex-col items-center justify-center">
              <div className="text-brand-purple font-bold text-3xl sm:text-5xl">
                {stats.cities}
              </div>
              <div className="font-bold text-xl md:text-2xl text-gray-700">
                Cities
              </div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="text-brand-purple font-bold text-3xl sm:text-5xl">
                {stats.states}
              </div>
              <div className="font-bold text-xl md:text-2xl text-gray-700">
                States
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="z-10 mx-2 md:mx-auto md:max-w-3xl pb-16">
        <form
          onSubmit={handleSubmit(submit)}
          id="mainForm"
          className="w-full pt-8"
        >
          <div className="bg-white rounded-lg p-5 border shadow-lg text-gray-700">
            <h2 className="text-xl md:text-2xl font-bold text-brand-purple mb-3 text-center">
              Walk in a #walkthevote
            </h2>

            <div className="text-gray-700 sm:mx-12 mt-4">
              Fill out your absentee ballot and deliver it yourself, safely, to
              an official and secure ballot drop box near you. Do it together,
              loud and public, with your community.
            </div>
            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 mt-4">
              <div>
                <Input
                  name="fullName"
                  register={register}
                  registerArgs={{ required: true }}
                  type="text"
                  placeholder="Full Name"
                  onBlur={async () => await trigger('fullName')}
                />
                {errors.fullName && <FormError>Please enter a value</FormError>}
              </div>
              <div>
                <Input
                  name="email"
                  register={register}
                  registerArgs={{
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Email invalid',
                    },
                  }}
                  type="email"
                  placeholder="Email"
                  onBlur={async () => await trigger('email')}
                />
                {errors.email && (
                  <FormError>Please enter a valid email address</FormError>
                )}
              </div>
              <div>
                <Input
                  name="zip"
                  register={register}
                  defaultValue={zipFromQuery}
                  registerArgs={{
                    minLength: 5,
                    required: true,
                  }}
                  type="text"
                  placeholder="Zip"
                  onBlur={async () => await trigger('zip')}
                />
                {errors.zip && (
                  <FormError>Please enter a valid zip code</FormError>
                )}
              </div>
              <div>
                <Input
                  name="phone"
                  register={register}
                  registerArgs={{
                    validate(val) {
                      try {
                        const num = phoneUtil.parseAndKeepRawInput(val, 'US');
                        return phoneUtil.isValidNumberForRegion(num, 'US');
                      } catch (e) {
                        return false;
                      }
                    },
                    required: currentValues.type === 'captain',
                  }}
                  type="tel"
                  placeholder="Phone"
                  onBlur={async () => await trigger('phone')}
                />
                {errors.phone && (
                  <FormError>Please enter a phone number</FormError>
                )}
              </div>
            </div>

            <label className="mt-4 flex items-center cursor-pointer">
              <div className="flex-shrink-0">
                <Select
                  registerArgs={{ required: true }}
                  register={register}
                  name="type"
                >
                  <option value="captain">Yes</option>
                  <option value="walker">No</option>
                </Select>
              </div>
              <span className="ml-4">
                I want to lead my own #walkthevote walk!
              </span>
            </label>
            <Button
              disabled={loading}
              className={classNames([
                'mt-6 px-4 py-3',
                { 'cursor-wait bg-gray-200 text-black': loading },
              ])}
              type="submit"
            >
              Sign me up
            </Button>
            <div className="sm:text-center text-gray-600 mt-3 p-2 w-full">
              When you sign up, we'll send you the next... steps.
            </div>
            <div className="sm:text-center text-gray-600 mt-3 p-2 w-full">
              We'll only use your contact information to communicate information
              about this event.
            </div>
          </div>
        </form>
      </div>
      <div className=" mx-2 sm:mx-auto sm:max-w-3xl lg:max-w-4xl grid grid-cols-1 lg:grid-cols-3 gap-3">
        <blockquote>
          <p>This is such an excellent, excellent idea!</p>
          <cite>
            Debra Cleaver, CEO and founder of VoteAmerica, founder of Vote.org,
            ElectionDay.org
          </cite>
        </blockquote>

        <blockquote>
          <p>
            Democracy is not a spectator sport nor an individual exercise. It is
            a team sport and we are all critical players. We all need to walk
            the vote together!
          </p>
          <cite>
            Nate Persily, Professor of Law, Stanford, and founder of
            HealthyElections.Org
          </cite>
        </blockquote>

        <blockquote>
          <p>
            Hey, my neighbor went, and if they drop their ballot off, I can do
            it.
          </p>
          <cite>Chia Morgan, #walkthevote organizer in Flint</cite>
        </blockquote>
      </div>
      <div className="md:max-w-3xl lg:max-w-4xl mx-auto mt-16">
        <div className="mb-3 no-underline text-gray-600 text-center">
          As seen in
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          <a
            href="https://nbc25news.com/news/local/first-ever-walkthevote-parade-reminds-people-to-cast-early-ballots-to-be-counted"
            target="_blank"
            className=" text-gray-600 hover:text-gray-700 no-underline transform-colors duration-200 flex flex-col items-center justify-center"
          >
            <Fox width={80} />
          </a>
          <a
            href="https://www.nytimes.com/2020/10/01/us/politics/democrats-in-person-voting.html"
            target="_blank"
            className=" text-gray-600 hover:text-gray-700 no-underline transform-colors duration-200 flex flex-col items-center justify-center"
          >
            <NewYorkTimes width={250} />
          </a>
          <a
            href="https://drive.google.com/file/d/1cLP68XylNR-MUsxQCaPcnLlAIkWud5sJ/view"
            target="_blank"
            className=" text-gray-600 hover:text-gray-700 no-underline transform-colors duration-200 flex flex-col items-center justify-center"
          >
            <ABC width={150} />
          </a>
        </div>
      </div>
      <div className="mt-24 text-sm mx-2 md:mx-auto md:max-w-3xl">
        <p className="mb-3">
          #walkthevote is a non-partisan movement of activists, organizers,
          technologists -- ordinary people of all stripes -- coming together to
          help voters across the country drop off their ballots in person. We
          are a group of committed citizens working with local community leaders
          across the country.
        </p>

        <p>
          We are working with local community leaders and organizations to
          support their existing efforts If you want to help us organize
          #walkthevote nationally, please email us to
          questions@wewalkthevote.com.
        </p>
      </div>
    </>
  );
}
