import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Route, Switch, useLocation } from 'react-router';
import CreateMobilizeEvent from './pages/CreateMobilizeEvent';
import CaptureInterest from './pages/CaptureInterest';
import CaptainInterestThankYou from './pages/CaptureInterest/CaptainInterestThankYou';
import CreateMobilizeEventThankYou from './pages/CreateMobilizeEvent/CreateMobilizeEventThankYou';
import './tailwind.output.css';
import WalkerInterestThankYou from './pages/CaptureInterest/WalkerInterestThankYou';
import TagManager from 'react-gtm-module';

const useAnalytics =
  !window.location.href.includes('localhost') &&
  !window.location.href.includes('staging');

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

if (useAnalytics) {
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (useAnalytics) {
      // ReactGA.initialize(process.env.REACT_APP_GA);
    }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);

  return (
    <Switch>
      <Route path="/" exact>
        <CaptureInterest />
      </Route>

      <Route path="/interest/captain/thank-you">
        <CaptainInterestThankYou />
      </Route>

      <Route path="/interest/walker/thank-you">
        <WalkerInterestThankYou />
      </Route>

      <Route path="/details/thank-you">
        <CreateMobilizeEventThankYou />
      </Route>
      <Route path="/details/:interestId">
        <CreateMobilizeEvent />
      </Route>
    </Switch>
  );
}

export default App;
