import React from 'react';
import { Link } from 'react-router-dom';

export default ({ showSubtext = true }) => (
  <div className="pt-4 sm:pt-6">
    <Link to="/" className="flex flex-row justify-center w-full mb-4 md:my-8">
      <img
        src="/logo.png"
        alt="#walkthevote"
        height="auto"
        width="450"
        className="px-12 object-scale-down w-full md:max-w-sm"
      />
    </Link>
    <h2 className="font-bold text-xl md:text-2xl text-gray-700 px-6 md:text-center max-w-2xl mx-auto">
      Join a “voting parade” in your community, so you can deliver your ballot
      to a ballot drop box near you — safely and securely.
    </h2>
  </div>
);
