import axios from 'axios';
export interface Stats {
  cities: number;
  timeslots: number;
  states: number;
  walkers: number;
}
export default async function getStats() {
  const { data } = await axios.get<Stats>(
    `${process.env.REACT_APP_API_URL}/mobilize/stats`
  );
  return data;
}
