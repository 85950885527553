import * as React from 'react';

const defaultWidth = 1000;

export default function Fox({ width = defaultWidth }) {
  const transformStr = `scale(${width / defaultWidth})`;
  return (
    <svg
      // x="0px"
      // y="0px"
      width={width}
      height={(width * 430.569) / defaultWidth} //"430.569"
      className="fill-current"
      // viewBox="0 0 999.99997 430.56899"
    >
      <g transform="matrix(1.7638893,0,0,1.7638893,0,9.4018555e-5)">
        <g>
          <path
            transform={transformStr}
            d="m 68.868,66.704 0,31.729 67.064,0 0,66.345 -67.064,0 0,78.6 L 0,243.378 0,0 l 146.388,0 4.688,66.704 z"
          />
          <path
            transform={transformStr}
            d="m 347.686,37.411 c 22.847,23.321 34.277,51.473 34.277,84.45 0,33.216 -11.431,61.483 -34.277,84.807 -22.851,23.32 -50.411,34.98 -82.682,34.98 -32.508,0 -60.191,-11.66 -83.037,-34.98 -22.852,-23.323 -34.275,-51.591 -34.275,-84.807 0,-32.978 11.423,-61.129 34.275,-84.45 22.846,-23.321 50.529,-34.982 83.037,-34.982 32.27,0 59.831,11.661 82.682,34.982 z m -102.47,133.212 c 0,5.423 1.999,10.132 6.007,14.135 4.002,4.008 8.834,6.008 14.487,6.008 5.654,0 10.424,-2 14.311,-6.008 3.888,-4.003 5.831,-8.712 5.831,-14.135 l 0,-98.584 c 0,-5.653 -1.943,-10.479 -5.831,-14.487 -3.886,-4.002 -8.656,-6.007 -14.311,-6.007 -5.653,0 -10.484,2.005 -14.487,6.007 -4.009,4.008 -6.007,8.834 -6.007,14.487 l 0,98.584 z"
          />
          <path
            transform={transformStr}
            d="m 489.407,244.102 -34.612,-61.658 -33.532,61.658 -75.718,0 L 417.658,117.904 349.511,0 427.393,0 455.878,52.642 484.722,0 l 75.356,0 -66.704,117.182 73.555,126.919 -77.522,0 z"
          />
        </g>
      </g>
    </svg>
  );
}
