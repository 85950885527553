// import 'typeface-bitter';
// import 'typeface-lato';
import 'typeface-metropolis';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <div className="pt-16 text-xs mx-auto flex flex-col items-center">
        <a rel="license" href="http://creativecommons.org/licenses/by/3.0/">
          <img
            alt="Creative Commons License"
            className="border-0"
            src="https://i.creativecommons.org/l/by/3.0/88x31.png"
          />
        </a>
        <br />
        This work is licensed under a{' '}
        <a rel="license" href="http://creativecommons.org/licenses/by/3.0/">
          Creative Commons Attribution 3.0 Unported License
        </a>
        .<br />
        <div>
          If you'd like to support our work, you contribute{' '}
          <a target="_blank" href="https://opencollective.com/walkthevote">
            here
          </a>
        </div>
        .
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
